<template>
  <div>
    <v-text-field
      v-if="mask"
      class="v-input custom-input-field v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--outlined"
      filled
      outlined
      dense
      :prefix="prefix"
      v-mask="mask"
      @focus="onFocus"
      :type="type"
      :disabled="disabled"
      :suffix="suffix"
      :value="value"
      :counter="counter"
      :placeholder="placeholder"
      :error-messages="errors"
      :label="label"
      @input="$emit('input', $event)"
      :required="required"
      ><slot></slot>
    </v-text-field>
    <v-text-field
      v-else
      class="custom-input-field"
      filled
      outlined
      dense
      :suffix="suffix"
      :placeholder="placeholder"
      :type="type"
      :disabled="disabled"
      :value="value"
      :counter="counter"
      :prefix="prefix"
      :error-messages="errors"
      :label="label"
      @input="$emit('input', $event)"
      :required="required"
      ><slot></slot
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "InputField",
  props: {
    prefix: {
      type: String
    },
    suffix: {
      type: String
    },
    placeholder: {
      type: String
    },
    mask: {
      type: String
    },
    type: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    counter: {
      type: [Boolean, Number, String]
    },
    label: {
      type: String
    },
    value: {
      type: [Number, String]
    },
    errors: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  methods: {
    onFocus() {
      if (this.mask == "+7 (9##) ###-##-##" && this.value == "") {
        this.$emit("input", "+79");
      } else if (this.mask == "+7 (###) ###-##-##" && this.value == "") {
        this.$emit("input", "+7");
      }
    }
  }
};
</script>

<style>
.v-input input {
  border-bottom: 0 !important;
}
.v-input input:focus {
  border-bottom: 0 !important;
  box-shadow: unset !important;
}
</style>
